.videogular-container {
  width: 100%;
  height: 320px;
  margin: auto;
  overflow: hidden;
}

@media (min-width: 1200px) {
  .videogular-container {
    width: 1170px;
    height: 658.125px;
  }

  .videogular-container.audio {
    width: 1170px;
    height: 50px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .videogular-container {
    width: 940px;
    height: 528.75px;
  }

  .videogular-container.audio {
    width: 940px;
    height: 50px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .videogular-container {
    width: 728px;
    height: 409.5px;
  }

  .videogular-container.audio {
    width: 728px;
    height: 50px;
  }
}

videogular vg-controls .controls-container, [videogular] vg-controls .controls-container {
  background-color: transparent !important;
}
