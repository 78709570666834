@import "globals";


@font-face {
  font-family: 'rcilight';
  src: url('../assets/fonts/rci-light-webfont.eot');
  src: url('../assets/fonts/rci-light-webfont.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/rci-light-webfont.woff2') format('woff2'),
  url('../assets/fonts/rci-light-webfont.woff') format('woff'),
  url('../assets/fonts/rci-light-webfont.ttf') format('truetype'),
  url('../assets/fonts/rci-light-webfont.svg#rcilight') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'rcibook';
  src: url('../assets/fonts/rci-book-webfont.eot');
  src: url('../assets/fonts/rci-book-webfont.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/rci-book-webfont.woff2') format('woff2'),
  url('../assets/fonts/rci-book-webfont.woff') format('woff'),
  url('../assets/fonts/rci-book-webfont.ttf') format('truetype'),
  url('../assets/fonts/rci-book-webfont.svg#rcibook') format('svg');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'rciblack';
  src: url('../assets/fonts/rci-black-webfont.eot');
  src: url('../assets/fonts/rci-black-webfont.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/rci-black-webfont.woff2') format('woff2'),
  url('../assets/fonts/rci-black-webfont.woff') format('woff'),
  url('../assets/fonts/rci-black-webfont.ttf') format('truetype'),
  url('../assets/fonts/rci-black-webfont.svg#rciblack') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nissanFont';
  src: url('../assets/fonts/nissan/regular.otf') format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nissanBold';
  src: url('../assets/fonts/nissan/bold.otf') format("opentype");
  font-weight: normal;
  font-style: normal;
}

////////RENAULT FONT
@font-face {
  font-family: 'renault';
  src: url("../assets/fonts/renault/RenaultLifeWeb-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'renault';
  src: url("../assets/fonts/renault/RenaultLifeWeb-Bold.woff") format("woff");
  font-weight: bold;
}
@font-face {
  font-family: 'renaultBold';
  src: url("../assets/fonts/renault/RenaultLifeWeb-Bold.woff") format("woff");
}
@font-face {
  font-family: 'renault';
  src: url("../assets/fonts/renault/RenaultLifeWeb-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'renault';
  src: url("../assets/fonts/renault/RenaultLifeWeb-Italic.woff") format("woff");
  font-style: italic;
}
@font-face {
  font-family: 'renault';
  src: url("../assets/fonts/renault/RenaultLifeWeb-Light.woff") format("woff");
  font-style: italic;
  font-weight: normal;
}

.fontArial{
  font-family: Arial;
}

.fontArial strong{
  font-weight: bold;
  font-family: Arial;
}

/*//////////ARIAL FONT//////////*/

.fontArial .large{
  font-size:60px;
}

.fontArial .medium{
  font-size:38px;
}

.fontArial .small{
  font-size:26px;
}

/*GRID EVIDENCE*/
.fontArial .large_grid{
  font-size:36px;
}

.fontArial .medium_grid{
  font-size:24px;
}

.fontArial .small_grid{
  font-size:18px;
}

/*//////////RCI FONT//////////*/

.fontRCI{
  font-family: 'rcibook';
}

.fontRCI strong{
  font-family:'rciblack';
}

.fontRCI .large{
  font-size:60px;
}
.fontRCI .large strong{
  font-family:'rciblack';
}

.fontRCI .medium{
  font-size:38px;
}

.fontRCI .medium strong{
  font-family:'rciblack';
}

.fontRCI .small{
  font-size:26px;
}

.fontRCI .small strong{
  font-family:'rciblack';
}

.fontRCI .modalFont{
  font-size:13px;
}

/*GRID EVIDENCE*/


/*//////////RCI FONT//////////*/

.fontRci{
  font-family: 'rcibook';
}

.fontRci strong{
  font-family:'rciblack';
}

.fontRci .large{
  font-size:60px;
}
.fontRci .large strong{
  font-family:'rciblack';
}

.fontRci .medium{
  font-size:38px;
}

.fontRci .medium strong{
  font-family:'rciblack';
}

.fontRci .small{
  font-size:26px;
}

.fontRci .small strong{
  font-family:'rciblack';
}

.fontRci .modalFont{
  font-size:13px;
}

/*GRID EVIDENCE*/

.fontRci .large_grid{
  font-size:36px;
}

.fontRci .large_grid strong{
  font-family:'rciblack';
}

.fontRci .medium_grid{
  font-size:24px;
}

.fontRci .medium_grid strong{
  font-family:'rciblack';
}

.fontRci .small_grid{
  font-size:18px;
}

.fontRci .small_grid strong{
  font-family:'rciblack';
}

/*END RCI FONT*/

/*//////////FONT NISSAN//////////*/

.fontNissan{
  font-family: 'nissanFont';
}

.fontNissan strong{
  font-family:'nissanBold';
}

.fontNissan .large{
  font-size:60px;
}
.fontNissan .large strong{
  font-family:'nissanBold';
}

.fontNissan .medium{
  font-size:38px;
}

.fontNissan .medium strong{
  font-family:'nissanBold';
}

.fontNissan .small{
  font-size:26px;
}

.fontNissan .small strong{
  font-family:'nissanBold';
}

.fontNissan .modalFont{
  font-size:13px;
}

/*GRID EVIDENCE*/

.fontNissan .large_grid{
  font-size:36px;
}

.fontNissan .large_grid strong{
  font-family:'nissanBold';
}

.fontNissan .medium_grid{
  font-size:24px;
}

.fontNissan .medium_grid strong{
  font-family:'nissanBold';
}

.fontNissan .small_grid{
  font-size:18px;
}

.fontNissan .small_grid strong{
  font-family:'nissanBold';
}

/*END RCI FONT*/

/*//////////FONT NISSAN//////////*/

.fontRenault{
  font-family: 'renault';
}

.fontRenault strong{
  font-family: 'renaultBold';
}

.fontRenault .large{
  font-size:60px;
}
.fontRenault .medium{
  font-size:38px;
}

.fontRenault .small{
  font-size:26px;
}

.fontRenault .modalFont{
  font-size:13px;
}

/*GRID EVIDENCE*/

.fontRenault .large_grid{
  font-size:36px;
}

.fontRenault .medium_grid{
  font-size:24px;
}

.fontRenault .small_grid{
  font-size:18px;
}
/*END RCI FONT*/