@font-face {
	font-family: "Flaticon";
	src: url("../assets/fonts/ico/flaticon.eot");
	src: url("../assets/fonts/ico/flaticon.eot#iefix") format("embedded-opentype"),
	url("../assets/fonts/ico/flaticon.woff") format("woff"),
	url("../assets/fonts/ico/flaticon.ttf") format("truetype"),
	url("../assets/fonts/ico/flaticon.svg") format("svg");
	font-weight: normal;
	font-style: normal;
}
[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
	font-family: Flaticon;
        font-size: 60px;
font-style: normal;
}.flaticon-arrow487:before {
	content: "\e000";
}
.flaticon-arrowhead7:before {
	content: "\e001";
}
.flaticon-back31:before {
	content: "\e002";
}
.flaticon-back36:before {
	content: "\e003";
}
.flaticon-direction49:before {
	content: "\e004";
}
.flaticon-fast44:before {
	content: "\e005";
}
.flaticon-home113:before {
	content: "\e006";
}
.flaticon-home168:before {
	content: "\e007";
}
.flaticon-home4:before {
	content: "\e008";
}
.flaticon-home78:before {
	content: "\e009";
}
.flaticon-house204:before {
	content: "\e00a";
}
.flaticon-house3:before {
	content: "\e00b";
}
.flaticon-info1:before {
	content: "\e00c";
}
.flaticon-info28:before {
	content: "\e00d";
}
.flaticon-info31:before {
	content: "\e00e";
}
.flaticon-info34:before {
	content: "\e00f";
}
.flaticon-information3:before {
	content: "\e010";
}
.flaticon-information73:before {
	content: "\e011";
}
.flaticon-leftarrow1:before {
	content: "\e012";
}
.flaticon-left2:before {
	content: "\e013";
}
.flaticon-left207:before {
	content: "\e014";
}
.flaticon-left209:before {
	content: "\e015";
}
.flaticon-left210:before {
	content: "\e016";
}
.flaticon-rewind44:before {
	content: "\e017";
}
.flaticon-right218:before {
	content: "\e018";
}
.flaticon-right222:before {
	content: "\e019";
}
.flaticon-right230:before {
	content: "\e01a";
}
.flaticon-round52:before {
	content: "\e01b";
}
