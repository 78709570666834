table.keyboardInputMaster {
  position:absolute;
  font:normal 16px Arial,sans-serif;
  border-top:1px solid #eeeeee;
  border-right:1px solid #888888;
  border-bottom:1px solid #444444;
  border-left:1px solid #cccccc;
  -webkit-box-shadow:0px 2px 10px #444444;
  -moz-box-shadow:0px 2px 10px #444444;
  box-shadow:0px 2px 10px #444444;
  opacity:0.95;
  filter:alpha(opacity=95);
  background-color:#dddddd;
  text-align:left;
  z-index:1000000;
  width:auto;
  height:auto;
  min-width:0;
  min-height:0;
  margin:0px;
  padding:0px;
  line-height:normal;
  -moz-user-select:none;
  cursor:default;
}
table.keyboardInputMaster {
  -webkit-border-top-left-radius:0.6em;
  -webkit-border-top-right-radius:0.6em;
  -moz-border-top-left-radius:0.6em;
  -moz-border-top-right-radius:0.6em;
  border-top-left-radius:0.6em;
  border-top-right-radius:0.6em;
}
table.keyboardInputMaster.relativeKeyboard {
  -webkit-border-radius:0.6em;
  -moz-border-radius:0.6em;
  border-radius:0.6em;
}

table.keyboardInputMaster * {
  position:static;
  color:#000000;
  background:transparent;
  font:normal 16px Arial,sans-serif;
  width:auto;
  height:auto;
  min-width:0;
  min-height:0;
  margin:0px;
  padding:0px;
  border:0px none;
  outline:0px;
  vertical-align:baseline;
  line-height:1.3em;
}
table.keyboardInputMaster table {
  table-layout:auto;
}
table.keyboardInputMaster.keyboardInputSize1,
table.keyboardInputMaster.keyboardInputSize1 * {
  font-size:13px;
}
table.keyboardInputMaster.keyboardInputSize3,
table.keyboardInputMaster.keyboardInputSize3 * {
  font-size:20px;
}
table.keyboardInputMaster.keyboardInputSize4,
table.keyboardInputMaster.keyboardInputSize4 * {
  font-size:24px;
}
table.keyboardInputMaster.keyboardInputSize5,
table.keyboardInputMaster.keyboardInputSize5 * {
  font-size:28px;
}

table.keyboardInputMaster thead tr th {
  padding:0.3em 0.3em 0.1em 0.3em;
  background-color:#999999;
  white-space:nowrap;
  text-align:right;
}
table.keyboardInputMaster thead tr th {
  -webkit-border-radius:0.6em 0.6em 0px 0px;
  -moz-border-radius:0.6em 0.6em 0px 0px;
  border-radius:0.6em 0.6em 0px 0px;
}
table.keyboardInputMaster thead tr th div {
  float:left;
  font-size:130% !important;
  height:1.3em;
  font-weight:bold;
  position:relative;
  z-index:1;
  margin-right:0.5em;
  cursor:pointer;
  background-color:transparent;
}
table.keyboardInputMaster thead tr th div ol {
  position:absolute;
  left:0px;
  top:90%;
  list-style-type:none;
  height:9.4em;
  overflow-y:auto;
  overflow-x:hidden;
  background-color:#f6f6f6;
  border:1px solid #999999;
  display:none;
  text-align:left;
  width:12em;
}
table.keyboardInputMaster thead tr th div ol li {
  padding:0.2em 0.4em;
  cursor:pointer;
  white-space:nowrap;
  width:12em;
}
table.keyboardInputMaster thead tr th div ol li.selected {
  background-color:#ffffcc;
}
table.keyboardInputMaster thead tr th div ol li:hover,
table.keyboardInputMaster thead tr th div ol li.hover {
  background-color:#dddddd;
}
table.keyboardInputMaster thead tr th span,
table.keyboardInputMaster thead tr th strong,
table.keyboardInputMaster thead tr th small,
table.keyboardInputMaster thead tr th big {
  display:inline-block;
  padding:0px 0.4em;
  height:1.4em;
  line-height:1.4em;
  border-top:1px solid #e5e5e5;
  border-right:1px solid #5d5d5d;
  border-bottom:1px solid #5d5d5d;
  border-left:1px solid #e5e5e5;
  background-color:#cccccc;
  cursor:pointer;
  margin:0px 0px 0px 0.3em;
  -webkit-border-radius:0.3em;
  -moz-border-radius:0.3em;
  border-radius:0.3em;
  vertical-align:middle;
  -webkit-transition:background-color .15s ease-in-out;
  -o-transition:background-color .15s ease-in-out;
  transition:background-color .15s ease-in-out;
}
table.keyboardInputMaster thead tr th strong {
  font-weight:bold;
}
table.keyboardInputMaster thead tr th small {
  -webkit-border-radius:0.3em 0px 0px 0.3em;
  -moz-border-radius:0.3em 0px 0px 0.3em;
  border-radius:0.3em 0px 0px 0.3em;
  border-right:1px solid #aaaaaa;
  padding:0px 0.2em 0px 0.3em;
}
table.keyboardInputMaster thead tr th big {
  -webkit-border-radius:0px 0.3em 0.3em 0px;
  -moz-border-radius:0px 0.3em 0.3em 0px;
  border-radius:0px 0.3em 0.3em 0px;
  border-left:0px none;
  margin:0px;
  padding:0px 0.3em 0px 0.2em;
}
table.keyboardInputMaster thead tr th span:hover,
table.keyboardInputMaster thead tr th span.hover,
table.keyboardInputMaster thead tr th strong:hover,
table.keyboardInputMaster thead tr th strong.hover,
table.keyboardInputMaster thead tr th small:hover,
table.keyboardInputMaster thead tr th small.hover,
table.keyboardInputMaster thead tr th big:hover,
table.keyboardInputMaster thead tr th big.hover {
  background-color:#dddddd;
}

table.keyboardInputMaster tbody tr td {
  text-align:left;
  padding:0.2em 0.3em 0.3em 0.3em;
  vertical-align:top;
}
table.keyboardInputMaster tbody tr td div {
  text-align:center;
  position:relative;
  zoom:1;
}
table.keyboardInputMaster tbody tr td table {
  white-space:nowrap;
  width:100%;
  border-collapse:separate;
  border-spacing:0px;
}
table.keyboardInputMaster tbody tr td.keyboardInputNumpad table {
  margin-left:0.2em;
  width:auto;
}
table.keyboardInputMaster tbody tr td table.keyboardInputCenter {
  width:auto;
  margin:0px auto;
}

table.keyboardInputMaster tbody tr td table tbody tr td {
  vertical-align:middle;
  padding:0px 0.45em;
  white-space:pre;
  height:1.8em;
  font-family:'Lucida Console','Arial Unicode MS',monospace;
  border-top:1px solid #e5e5e5;
  border-right:1px solid #5d5d5d;
  border-bottom:1px solid #5d5d5d;
  border-left:1px solid #e5e5e5;
  background-color:#eeeeee;
  cursor:default;
  min-width:0.75em;
  -webkit-border-radius:0.2em;
  -moz-border-radius:0.2em;
  border-radius:0.2em;
  -webkit-transition:background-color .15s ease-in-out;
  -o-transition:background-color .15s ease-in-out;
  transition:background-color .15s ease-in-out;
}
table.keyboardInputMaster.relativeKeyboard tbody tr td table tbody tr td.last {
  width:99%;
}
table.keyboardInputMaster tbody tr td table tbody tr td.space {
  padding:0px 4em;
}
table.keyboardInputMaster tbody tr td table tbody tr td.deadkey {
  background-color:#ccccdd;
}
table.keyboardInputMaster tbody tr td table tbody tr td.target {
  background-color:#ddddcc;
}
table.keyboardInputMaster tbody tr td table tbody tr td:hover,
table.keyboardInputMaster tbody tr td table tbody tr td.hover {
  border-top:1px solid #d5d5d5;
  border-right:1px solid #555555;
  border-bottom:1px solid #555555;
  border-left:1px solid #d5d5d5;
  background-color:#cccccc;
}
table.keyboardInputMaster thead tr th span:active,
table.keyboardInputMaster thead tr th span.pressed,
table.keyboardInputMaster tbody tr td table tbody tr td:active,
table.keyboardInputMaster tbody tr td table tbody tr td.pressed {
  border-top:1px solid #555555 !important;
  border-right:1px solid #d5d5d5;
  border-bottom:1px solid #d5d5d5;
  border-left:1px solid #555555;
  background-color:#cccccc;
}

table.keyboardInputMaster tbody tr td table tbody tr td small {
  display:block;
  text-align:center;
  font-size:0.6em !important;
  line-height:1.1em;
}

table.keyboardInputMaster tbody tr td div label {
  position:absolute;
  bottom:0.2em;
  left:0.3em;
}
table.keyboardInputMaster tbody tr td div label input {
  background-color:#f6f6f6;
  vertical-align:middle;
  font-size:inherit;
  width:1.1em;
  height:1.1em;
}
table.keyboardInputMaster tbody tr td div var {
  position:absolute;
  bottom:0px;
  right:3px;
  font-weight:bold;
  font-style:italic;
  color:#444444;
}

.keyboardInputInitiator {
  margin:0px 3px;
  vertical-align:middle;
  cursor:pointer;
}

.keyboardsArea table {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}
