/*============================*/
/*=== author:Mathias Smal ====*/
/*============================*/


.grid_orizzontal{
position: absolute;
width: 100%;
//height: 30%;
padding: 10px;
box-sizing: border-box;
overflow: hidden;
text-overflow: ellipsis;
z-index: 999;
/*QUESTE 3 CLASSI DI SEGUITO SONO DA TOGLIERE MI SONO SERVITE A ME PER I TEST*/
//border:1px solid black;
//background-color: red;
//top:0px;
}

.grid_vertical{
position: absolute;
width: 360px;
height: 100%;
padding: 10px;
box-sizing: border-box;
overflow: hidden;
text-overflow: ellipsis;
z-index: 999;
/*QUESTE 3 CLASSI DI SEGUITO SONO DA TOGLIERE MI SONO SERVITE A ME PER I TEST*/
//border:1px solid black;
//background-color: red;
//top:0px;
}

.grid_vertical_margin{
  position: absolute;
  width: 340px;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 999;
  /*QUESTE 3 CLASSI DI SEGUITO SONO DA TOGLIERE MI SONO SERVITE A ME PER I TEST*/
  //border:1px solid black;
  //background-color: red;
  //top:0px;
}


.grid_text{
display: table-cell;
//vertical-align: bottom;
//text-align: center;
///*MISURA CHE BISOGNA INSERIRE DINAMICAMENTE CALCOLATA PRENDENDO L'ALTEZZA DEL DIV GRID_VERTICAL O GRID_ORIZZONTAL E SOTTRAENDO IL PADDING (IN QUESTO CASO 10+10)*/
//height: 88px;
}
