/* initial css */
@import "../styles/css/reset";
@import "../styles/css/carousel";
@import "../styles/css/mainStyle";
@import "../styles/css/grid";
@import "../styles/css/bootstrap";
@import "../styles/css/slick";
@import "../styles/css/slick-theme";
@import "../styles/css/videogular";
@import "../styles/css/angularCustom";
@import "../styles/css/fontLibrary";
@import "../styles/css/flaticon";
@import "../styles/css/angular-virtual-keyboard";
@import "../styles/css/loading-bar";
@import "../styles/css/meteo";
@import "../styles/css/checkBoxCustom";
@import "../styles/css/animate";
@import "../styles/css/gameMemory";
@import "../styles/css/fancyBox";
@import "../styles/css/rooms";
//@import "../styles/css/animate";