.room__container {
    height: 100%;
    width: 100%;

    .slick-track {
        height: 100%;
    }
    .slick__container {
        margin-top: 40px;

        .slick-prev,
        .slick-next {
            height: 50px;
            width: 50px;
        }
        .slick-prev {
            left: -2px;
        }
        .slick-next {
            right: -2px;
        }
        .slick-prev:before,
        .slick-next:before {
            font-size: 50px;
            color: #184092;
        }
    }
    .slick-list {
        height: 100%;
    }
    .slick__item {
        height: 265px;
        width: 100%;
        padding: 18px 35px;
    }
    .slick__card {
        height: 100%;
        width: 300px;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
        background-color: white; 
    }
    .rooms__buttons__container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }
    .btn-primary__rooms:hover {
        color: white;
    }

    .slick-slider {
        margin-bottom: 0px !important;
    }

    .slick__card__header {
        font-size: 25px;
        font-weight: bold;
        padding: 10px;
    }

    .slick__card__body {
        padding: 10px;
        font-size: 15px;
        height: 100%;
        overflow: auto;

        li {
            line-height: 26px;
        }
    }

    .event__hour {
        color: #184092;
        font-weight: 600;
    }
}