@import "globals";

.tiles_relative{
  position: relative !important;
  text-align:center !important;
}

.marginBothSlide{
  margin-left:5px !important;
  margin-right: 5px !important;
}

.resizedHeight{
  height: auto;
  width: 100%;
}

.shadow1{
  text-shadow: 0 0px 6px rgba(0, 0, 0, 0.8) !important
}

.shadow0{
  text-shadow: none !important;
}

.ovewriteButtonCarousel{
  font-size: 54px !important;
  margin-top: -32px !important;
  height: 54px !important;
  width: 54px !important;
}

/* MODAL MODIFICATO */

.modal-backdrop{
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.modal-dialog-mat {
  position: absolute;
  top: 50% !important;
  transform: translate(0, -50%) !important;
  -ms-transform: translate(0, -50%) !important;
  -webkit-transform: translate(0, -50%) !important;
  margin: auto 18%;
  width: 60%;
}

.modal-body > p{
  margin:0px !important;
  font-size: 16px;
}

.modal-footer{
  text-align: center !important;
}

.sectionModularMargin{
  height: 1810px;
  width: 1050px;
  overflow: hidden;
}

.btn-primary{
  background-color: #888888 !important;
  border-color: #888888 !important;
  border-radius: 0px !important;
  font-size: 16px !important;
}

/* MY FIRST */
.inputCustomClass{
  width: 90% !important;
  margin: 10px auto !important;
}

/* ANGULAR MOMENT SCROLL */
/* Will be compiled down to a single stylesheet with your sass files */
#mycontainer {
  width: 100%;
  height: 1080px;
}
#mycontainerPortrait {
  width: 100%;
  height: 1800px;
}

.listitem {
  width: 100%;
  font-size: 2rem;
  height: 3rem;
  line-height: 3rem;
}

.listitem:nth-child(even){
  background-color: #eee;
}
.listitem:nth-child(odd){
  background-color: #ddd;
}

#logger {
  font-size: 1rem;
  line-height: 1;
  color: red;
  height: 5rem;
  overflow-y: scroll;
  width: 100%;
}

#mycontainer li{
  margin: 0;
  padding:0;
}

.swipe_no_margin{
  width: 900px !important;
  height: auto !important;
}

.buttonPlay{
  //color: grey;
  font-size: 80px;
}

.buttonPlayReduced{
  //color: grey;
  font-size: 40px;
  padding-bottom:20px;
  padding-right:5px;
}

.form_brand{
  text-align:center;
  //margin-top:30px;
}

.form_brand .input_form{
  height:50px;
  width:680px;
  border:2px solid #dedede;
  -webkit-border-radius: 6px 6px 6px 6px;
  border-radius: 6px 6px 6px 6px;
  padding-left: 10px;
  margin-bottom: 25px;
  color:#686667;
}

.form_brand input[placeholder] {
  font-size: 20px;
}

.form_brand textarea[placeholder]{
  font-size: 21px;
}

.form_brand .textarea_form{
  width:680px;
  height: 130px;
  border:2px solid #dedede;
  -webkit-border-radius: 6px 6px 6px 6px;
  border-radius: 6px 6px 6px 6px;
  padding-left: 10px;
  padding-top: 10px;
  margin-bottom: 25px;
  color:#686667;
}

.form_brand .textarea {
  resize: none;
  font-family: Arial;
}

.form_brand button[type=submit]{
  border:0px;
  background-color: #000;
  height:50px;
  color:black;
  font-size: 20px;
  width: 200px;
  text-transform: uppercase;
}

.backgroundWhite{
  background-color: white;
  color: black;
}

.marginTop40{
 margin-top:40px;
}

*{
  -webkit-font-smoothing: antialiased;
}

input,form,textarea {
  -webkit-touch-callout: default !important;
  -webkit-user-select: auto !important;
}

//.center-modal{
//  transform: translate(0, 50%) !important;
//  -ms-transform: translate(0, 50%) !important; /* IE 9 */
//  -webkit-transform: translate(0, 50%) !important; /* Safari and Chrome */
//}

.center-modal{
  position: absolute;
  top: 50% !important;
  transform: translate(0, -50%) !important;
  -ms-transform: translate(0, -50%) !important;
  -webkit-transform: translate(0, -50%) !important;
  margin: 0 auto;
  //width: 60%;
}

.full videogular .iconButton, [videogular] .iconButton{
  font-size: 38px !important;
  margin-right: 13px;
  text-shadow: 0 0 2px #000000;
}

.full videogular vg-controls, [videogular] vg-controls{
  height:62px !important;
}

.full videogular vg-mute-button, [videogular] vg-mute-button{
  width: auto !important;
  text-shadow: 0 0 2px #000000;
}

.tag {
  float: right;
  position: absolute;
  right: 0px;
  bottom: 0px;
  z-index: 1000;
  //background-color: #92AD40;
  padding: 20px;
  //color: #FFFFFF;
  font-weight: bold;
}

.tiles_html_landscape{
  //height:230px;
  width: 1080px;
  overflow:hidden;
  position: relative;
}

.container_call_to_action_landscape{
  //position:absolute;
  //bottom:0px;
  padding: 5px;
  width: 100%;
  height: 150px;
  z-index: 999;
  /*border:1px solid red;*/
  box-sizing: border-box;
  /*PARAMETRO ALLINEAMENTO BOTTONI CALL TO ACTION*/
  text-align: center;
}

.contactFormStyle{
  width: 1050px;
  padding: 20px !important;
}

.errorForm{
  color: red;
  clear:both;
  margin-top:10px;
}
.marginBottom20{
  margin-bottom: 20px;
}
.marginTop20{
  margin-top: 20px;
}
.customPrivacy{
  height: 50px;
  width: 680px;
  margin: 0 auto;
  clear: both;
  text-align: left;
}

.customPrivacy .customPrivacyLeft{
  width: auto;
  float: left;
}
.customPrivacy .customPrivacyRight{
  margin-left:10px;
  width: 610px;
  float: left;
}

//GESTIONE PRIVACY MODAL
.customPrivacyModal{
  //height: auto;
  width: 540px;
  margin: 20px auto;
  z-index: 250;
  clear: both;
  text-align: left;
}

.customPrivacyModal .customPrivacyLeft{
  width: auto;
  float: left;
}
.customPrivacyModal .customPrivacyRight{
  margin-left:10px;
  width: 500px;
  float: left;
  min-height:20px;
}

////NO TEMPLATE
.noLayout{
  background-color: #CCCCCC;
}

////CONTAINER NO TEMPLATE
.containerNoLayout{
  height: 1800px;
  width: 1080px;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
}

////CONTAINER NO TEMPLATE
.containerNoLayoutMargin{
  height: 1820px;
  width: 1080px;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
}

.linkablePrivacy{
  text-decoration: underline;
}

.fullWidthDiv{
  width: 100%;
}

.version_3_block_first_row_scroller{
  height:1440px;
  width: 1080px;
}

.version_3_block_first_row_landscape{
  height:1080px;
  width: 1080px;
}

.contactFormLandscape{
  max-height:718px;
}
.contactFormPortrait{
  max-height:790px;
}
.height499Slider{
  height: 499px !important;
}
.height524Slider{
  height: 524px !important;
}
.placeholderRandom{
  width: 100%;
  height: 100%;
}
.placeholderRandom img{
  width: 100%;
  height: 100%;
}

/////////////CONTAINER DESCRIZIONE
//.containerDetailCallPortrait{
//  max-height: 404px;
//}
//.containerDetailPortrait{
//  max-height: 550px;
//}
//////LANDSCAPE
//.containerDetailCallLandscape{
//  overflow: hidden;
//  max-height: 404px;
//}
//.containerDetailLandscape{
//  overflow: hidden;
//  max-height: 550px;
//}
///////PADDING 26
//.padding26{
//  padding:10px;
//}
///////V1
//.containerDetailV1CallPortrait{
//  max-height: 850px;
//}
//.containerDetailV1Portrait{
//  max-height: 1000px;
//}

.descriptionHTML ul{
  list-style: inherit;
  padding-left: 30px;
}
.descriptionHTML ol{
  list-style: decimal;
  padding-left: 30px;
}
.versionSidebar{
  position: absolute;
  bottom:4px;
  right:4px;
  font-size:10px;
  display: inline-block;
  font-family: Helvetica Neue, Helvetica, Arial;
}
.versionSidebarLandscape{
  box-sizing: border-box;
  float: left;
  padding-top: 1px;
  width: 120px;
  height: 14px;
  font-size: 10px;
  text-align: center;
  display: table-cell;
  font-family: Helvetica Neue, Helvetica, Arial;
}
.section_landscape_privilege{
  width: 1920px;
  height: 1080px;
}
.portraitCointainerFull{
  width: 1080px;
  height: 1920px;
}
.shadowText{
  text-shadow: 0 0 2px #000000;
}