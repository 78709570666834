
.font_content_details{
  //font-size:22px;
  //font-weight:bold;
}

.font_content_subtitle{
  font-size:33px;
  font-weight: bold;
}

.font-weight-bold{
  font-weight: bold;
}

.font40{
  font-size: 40px !important;
}

.display_inline{
  display: inline-block;
}

.float_left{
  float:left;
}

.float_right{
  float:right;
}

.overflowHidden{
  overflow:hidden;
}

.positionRelative{
  position:relative;
}

.margin10{
  margin: 10px;
}

.marginTop10{
  margin-top: 10px;
}

.marginTop20{
  margin-top: 20px;
}

.marginTop7{
  margin-top: 7px;
}

.marginTop5{
  margin-top: 5px;
}

.marginTop11{
  margin-top: 11px;
}

.marginTop15{
  margin-top: 15px;
}

.marginLeft7{
  margin-left: 7px;
}

.marginLeft10{
  margin-left: 10px;
}

.marginLeft13{
  margin-left: 13px;
}

.marginLeft20{
  margin-left: 20px;
}

.marginRight7{
  margin-right: 7px;
}

.marginRight10{
  margin-right: 10px;
}

.marginRight14{
  margin-right: 14px;
}

.marginRight20{
  margin-right: 20px;
}

.marginBottom5{
  margin-bottom: 5px;
}

.marginBottom9{
  margin-bottom: 9px;
}

.marginBottom13{
  margin-bottom: 13px;
}

.marginBottom10{
  margin-bottom: 10px;
}

.marginBottom20{
  margin-bottom: 20px;
}

.paddingLeft10{
  padding: 10px;
}

.paddingLeft15{
  padding-left: 15px;
}

.padding10{
  padding: 10px;
}

.paddingRight15{
  padding-right: 15px;
}

.paddingTop10{
  padding-top: 10px;
}

.paddingTop5{
  padding-top: 5px;
}

.paddingBottom5{
  padding-bottom: 5px;
}

.paddingTop15{
  padding-top: 15px;
}

.paddingBottom10{
  padding-bottom: 10px;
}

.paddingBottom15{
  padding-bottom: 15px;
}

.content_form_contact{
  margin:10px;
  text-align: center;
  display:table-cell;
  vertical-align:middle;
  //background-color: red;
}

.textAlign{
  text-align: center;
}

/*//////////////////DIMENSIONI PAGINE PORTRAIT//////////////////*/

.portrait{
  height: 1920px;
  width: 1080px;
  overflow: hidden;
}

.container{
  height: 1920px;
  width: 1080px;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
}

.section{
  height: 1920px;
  width: 1050px;
  overflow: hidden;
}

.subsection{
  height: 1776px;
  width: 1020px;
  overflow: hidden;
}


/*//////////////////DIMENSIONI PAGINE LANDSCAPE//////////////////*/

.landscape{
  height: 1080px;
  width: 1920px;
  overflow: hidden;
}

.container_landscape{
  height: 1080px;
  width: 1920px;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
}

.container_landscape_margin_less{
  height: 1070px;
  width: 1920px;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
}

.section_landscape{
  height: 1080px;
  width: 1800px;
  overflow: hidden;
}

.section_landscape_no_margin{
  height: 1080px;
  width: 1800px;
  overflow: hidden;
}


.section_landscape_full{
  height: 1070px;
  width: 1920px;
  overflow: hidden;
}

.section_landscape_margin_less{
  height: 1050px;
  width: 1920px;
  overflow: hidden;
}

/*//////////////////BLOCCHI CONTENITORI PER RIGA PORTRAIT//////////////////*/

.containerBlockHp1Less{
  height:524px;
  width: 1080px;
}

.containerBlockHp2Less{
  height:523px;
  width: 1080px;
}

.containerBlockHp3Less{
  height:350px;
  width: 1080px;
}


.containerBlockHp1{
  height:524px;
  width: 1050px;
}

.containerBlockHp2{
  height:523px;
  width: 1050px;
}

.containerBlockHp3{
  height:350px;
  width: 1050px;
}

.container_full_Block{
  height:360px;
  width: 1080px;
}

.container_modular_Block{
  height:360px;
  width: 1080px;
}

.footer_full_Block{
  height:100px;
  width: 1080px;
  padding:10px;
  box-sizing: border-box;
  background-color: rgba( 93 , 93 , 93 , 0.5 );
  position:relative;
}

.footer_full_Block_no_margin{
  height:120px;
  width: 1080px;
  padding:10px;
  box-sizing: border-box;
  background-color: rgba( 93 , 93 , 93 , 0.5 );
  position:relative;
}

.footer_full_Block_video_margin{
  height:100px;
  width: 1080px;
  position: absolute;
  bottom:0px;
  padding: 10px;
  z-index: 99;
  background-color: rgba( 93 , 93 , 93 , 0.5 );
  box-sizing: border-box;
  position:relative;
}

.footer_full_Block_video_no_margin{
  height:120px;
  width: 1080px;
  position: absolute;
  bottom:0px;
  padding: 10px;
  z-index: 99;
  background-color: rgba( 93 , 93 , 93 , 0.5 );
  box-sizing: border-box;
  position:relative;
}

/*FOOTER BTN PORTRAIT*/

.back_toolbar_btn{
  width:350px;
  display: inline-block;
  box-sizing: border-box;
  font-size: 50px;
  text-align: left;
}

.home_toolbar_btn{
  width:350px;
  display: inline-block;
  box-sizing: border-box;
  font-size: 50px;
  text-align: center;
}

.info_pagination_toolbar_btn{
  width:350px;
  //width:345px;
  display: inline-block;
  box-sizing: border-box;
  font-size: 50px;
  text-align: right;
}

.content_btn_no_margin{
  display: table-cell;
  vertical-align: middle;
  height: 100px;
  padding-top: 20px;
  line-height: 62px;
}

.content_btn_margin{
  display: table-cell;
  vertical-align: middle;
  height: 100px;
  padding-top: 10px;
  line-height: 62px;
}

.glyphicon{
  color:black;
}

/*END FOOTER BTN*/

.container_slider{
  height:499px;
  width: 1050px;
}


.version_1_block_first_row{
  height:435px;
  width: 1080px;
}

.version_1_block_second_row{
  height:1005px;
  width: 1080px;
}

.version_1_block_third_row{
  height:360px;
  width: 1080px;
}

.footer_row_portrait{
  height:360px;
  width: 1080px;
}


.version_2_block_first_row{
  height:110px;
  width: 1080px;
}

.version_2_block_second_row{
  height:650px;
  width: 1080px;
}

.version_2_block_third_row{
  height:90px;
  width: 1080px;
}

.version_2_block_fourth_row{
  height:590px;
  width: 1080px;
}

.version_2_block_fifth_row{
  height:360px;
  width: 1080px;
}

.version_3_block_first_row{
  height:1440px;
  width: 1080px;
}

/*//////////////////BLOCCHI CONTENITORI PER RIGA LANDSCAPE//////////////////*/

.containerLandscapeBlockHp1{
  height:1050px;
  width: 520px;
}

.containerLandscapeBlockHp1 img{
  height:1050px !important;
  width: 520px !important;
}


.containerLandscapeBlockHp2{
  height:1050px;
  width: 1050px;
}

.containerLandscapeBlockHp2 img{
  width: 1050px !important;
  height: 525px !important;
}


.containerLandscapeBlockHp3{
  height:1050px;
  width: 350px;
}

.container_slider_landscape{
  width: 1050px;
  height: 525px;
  overflow:hidden;
}

.containerLandscapeFullBlockHp{
  height:360px;
  width: 1800px;
}

.footerBarLandscape{
  height:1080px;
  width: 100px;
  box-sizing: border-box;
  background-color: rgba( 93 , 93 , 93 , 0.5 );
}

.footerBarLandscape_video{
  height:1080px;
  width: 100px;
  position: absolute;
  left:0px;
  z-index:99;
  box-sizing: border-box;
  background-color: rgba( 93 , 93 , 93 , 0.5 );
}

.footerBarLandscape_no_margin{
  height:1080px;
  width: 120px;
}

.footerBarLandscape_video_no_margin{
  height:1080px;
  width: 120px;
  position: absolute;
  left:0px;
  z-index: 99;
  background-color: rgba( 93 , 93 , 93 , 0.5 );
}


.version_1_landscape_block_first_column{
  height:1080px;
  width: 1080px;
}

.version_1_landscape_block_second_column{
  height:1080px;
  width: 360px;
}

.version_1_landscape_block_third_column{
  height:1080px;
  width: 360px;
}

.version_2_landscape_block_first_column{
  height:1080px;
  width: 1080px;
}

.version_2_landscape_block_second_column{
  height:1080px;
  width: 360px;
}

.version_2_landscape_block_third_column{
  height:1080px;
  width: 360px;
}

.version_3_landscape_block_first_column{
  height:1080px;
  width: 1080px;
}

.version_3_landscape_block_second_column{
  height:1080px;
  width: 360px;
}

.footer_column_landscape{
  height:1080px;
  width: 360px;
}

.version_3_landscape_block_third_column{
  height:1080px;
  width: 360px;
}

/*FOOTER BTN LANDSCAPE*/

.back_toolbar_btn_landscape{
  height:358px;
  box-sizing: border-box;
  font-size: 50px;
  text-align: center;
  padding-top:20px;
}

.home_toolbar_btn_landscape{
  height:358px;
  box-sizing: border-box;
  font-size: 50px;
  text-align: center;
  padding-top: 154px;
}

.info_pagination_toolbar_btn_landscape{
  height:342px;
  box-sizing: border-box;
  font-size: 50px;
  text-align: center;
  display: table-cell;
  vertical-align: bottom;
  width: 120px;
}

.info_pagination_toolbar_btn_landscape_no_margin{
  height:342px;
  box-sizing: border-box;
  font-size: 50px;
  text-align: center;
  display: table-cell;
  vertical-align: bottom;
  width: 120px;
}

/*.content_btn_no_margin{
display: table-cell;
vertical-align: middle;
height: 100px;   
padding-top: 6px;
}

.content_btn_margin{
display: table-cell;
vertical-align: middle;
height: 80px;   
padding-top: 6px;
}

.glyphicon{
color:black;    
}*/

/*END FOOTER BTN*/

.container_video_landscape_no_margin{
  width: 1920px;
  height: 1080px;
  overflow:hidden;
  position: relative;
}

/*//////////////////CALL TO ACTION//////////////////*/

.container_call_to_action{
  //position:absolute;
  bottom:0px;
  padding: 5px;
  width: 100%;
  //height: 150px;
  z-index: 999;
  /*border:1px solid red;*/
  box-sizing: border-box;
  /*PARAMETRO ALLINEAMENTO BOTTONI CALL TO ACTION*/
  text-align: center;
}

.btn_call_to_action{
  background-color: #CCCCCC;
  font-size: 22px;
  padding: 20px;
  margin: 5px;
  line-height: 22px;
  text-align: center;
  font-weight: bold;
  display: -webkit-inline-box;
}

/*//////////////////CALL TO ACTION//////////////////*/



/*//////////////////TILES PER MISURA IN PIXEL//////////////////*/

.tiles_1010x499{
  width: 1010px;
  height: 499px;
  overflow:hidden;
  position: relative;
}

.tiles_1010x505{
  width: 1010px;
  height: 505px;
  overflow:hidden;
  position: relative;
}

.tiles_1050x524{
  width: 1050px;
  height: 524px;
  overflow:hidden;
  position: relative;
}

.tiles_1050x525{
  width: 1050px;
  height: 525px;
  overflow:hidden;
  position: relative;
}


.tiles_720x360{
  width: 72px;
  height: 360px;
  overflow:hidden;
  position: relative;
}

.tiles_520x1050{
  width: 520px;
  height:1050px;
  position: relative;
}

.tiles_500x1030{
  width: 500px;
  height:1030px;
  position: relative;
}

.tiles_1080x360{
  width: 1080px;
  height: 360px;
  overflow:hidden;
  position: relative;
}

.tiles_75x523{
  width: 75px;
  height: 523px;
  overflow:hidden;
  position: relative;
}

.tiles_75x525{
  width: 75px;
  height: 525px;
  overflow:hidden;
  position: relative;
}

.tiles_55x505{
  width: 55px;
  height: 505px;
  overflow:hidden;
  position: relative;
}

.tiles_900x525{
  width: 900px;
  height: 525px;
  overflow:hidden;
  position: relative;
}

.tiles_860x505{
  width: 860px;
  height: 505px;
  overflow:hidden;
  position: relative;
}

.tiles_1080x100{
  width: 1080px;
  height: 100px;
  overflow:hidden;
  position: relative;
}

.tiles_1060x340{
  width: 1060px;
  height: 340px;
  overflow:hidden;
  position: relative;
}

.tiles_75x499{
  width: 75px;
  height: 499px;
  overflow:hidden;
  position: relative;
}

.tiles_900x523{
  width: 900px;
  height: 523px;
  overflow:hidden;
  position: relative;
}

.tiles_700x340{
  width: 700px;
  height: 340px;
  overflow:hidden;
  position: relative;
}

.tiles_860x499{
  width: 860px;
  height: 499px;
  overflow:hidden;
  position: relative;
}

.tiles_720x360{
  width: 720px;
  height: 360px;
  overflow:hidden;
  position: relative;
}

.tiles_350x350{
  width: 350px;
  height: 350px;
  overflow:hidden;
  position: relative;
}

.tiles_360x360{
  width: 360px;
  height: 360px;
  overflow:hidden;
  position: relative;
}

.tiles_360x435{
  width: 360px;
  height: 435px;
  overflow:hidden;
  position: relative;
}

.tiles_323x323{
  width: 323px;
  height: 323px;
  overflow:hidden;
  position: relative;
}

.tiles_340x340{
  width: 340px;
  height: 340px;
  overflow:hidden;
  position: relative;
}

.tiles_330x330{
  width: 330px;
  height: 330px;
  overflow:hidden;
  position: relative;
}

.tiles_1080x1005{
  height:1005px;
  width: 1080px;
  overflow:hidden;
  position: relative;
}

.tiles_1080x110{
  height:110px;
  width: 1080px;
  overflow:hidden;
  position: relative;
}

.tiles_1080x650{
  height:650px;
  width: 1080px;
  overflow:hidden;
  position: relative;
}

.tiles_1080x645{
  height:645px;
  width: 1080px;
  overflow:hidden;
  position: relative;
}

.tiles_1080x90{
  height:90px;
  width: 1080px;
  overflow:hidden;
  position: relative;
}

.tiles_571x345{
  height:345px;
  width: 571px;
  overflow:hidden;
  position: relative;
}


.tiles_509x345{
  height:345px;
  width: 509px;
  overflow:hidden;
  position: relative;
}

.tiles_1080x590{
  height:590px;
  width: 1080px;
  overflow:hidden;
  position: relative;
}

.tiles_720x435{
  height:435px;
  width: 720px;
  overflow:hidden;
  position: relative;
}

.tiles_1080x120{
  height:120px;
  width: 1080px;
  overflow:hidden;
  position: relative;
}

.tiles_1080x1440{
  height:1440px;
  width: 1080px;
  overflow:hidden;
  position: relative;
}

.tiles_1080x600{
  height:600px;
  width: 1080px;
  overflow:hidden;
  position: relative;
}

.tiles_1080x720{
  height:720px;
  width: 1080px;
  overflow:hidden;
  position: relative;
}

.tiles_1080x840{
  height:840px;
  width: auto;
  overflow:hidden;
  position: relative;
}

.tiles_1080x1080{
  height:1080px;
  width: 1080px;
  overflow:hidden;
  position: relative;
}

.tiles_1080x230{
  height:230px;
  width: 1080px;
  overflow:hidden;
  position: relative;
}

.tiles_1920x1080{
  height:1080px;
  width: 1920px;
  overflow:hidden;
  position: relative;
}

.tiles_1080x1920{
  height:1920px;
  width: 1080px;
  overflow:hidden;
  position: relative;
}

*{
    -webkit-font-smoothing: antialiased;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
}

input, form, textarea {
    -webkit-touch-callout: default !important;
    -webkit-user-select: auto !important;
}
