*{
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}
::-webkit-input-placeholder { /* WebKit browsers */
  text-transform: none;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  text-transform: none;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
  text-transform: none;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
  text-transform: none;
}

.gameLandscap #container {
  border-left: 10px solid #ff931e;
  border-right: 10px solid #ff931e;
  color: #555;
  //font-family: "RCI-Bk" !important;
  height: 1080px;
  //width: 1080px;
  overflow:hidden;
}

.gameLandscap  .registrazione { /**/
  margin-top:70px;
}

.gameLandscap  .centered {
  text-align:center;
}
.gameLandscap  .inputField {
  margin-bottom:10px;
  height: 30px;
}
.gameLandscap  .vmiddle{
  vertical-align:middle;
}
.gameLandscap  .errorInput {
  border: solid 1px red !important;
}
.gameLandscap  .errorMsg {
  color: red;
  font-weight: bold;
  text-align: center;
}

.gameLandscap  .sizeModal {
  width: 780px !important;
  margin: 0px auto;
}
.gameLandscap  .size48{
  font-size:48px;
}

.gameLandscap  .arancione {
  color: #ff931e;
}

.gameLandscap  .nero {
  color:black;
}

.gameLandscap  .bottoneHome {
  margin-left: 60px;
  margin-top: 15px;
}

.gameLandscap  .centered {
  text-align: center;
}

.gameLandscap  #giocaCTA {
  margin-right: -25px;
  margin-top: 50px;
  text-align: right;
}
.gameLandscap  #giocaCTAPlay {
  margin-right: -25px;
  margin-top: 50px;
  text-align: right;
}

.gameLandscap  .bold
{
  //font-family: 'RCI-Bold';
  font-weight: bold;
}

.gameLandscap  .bold24 {
  font-size:24px;
  //font-family:'RCI-Bold' !important;
  font-weight: bold;
  color:#ff931e;
  position:relative;
  top:-10px;
  line-height:25px;
}
.gameLandscap  .size24 {
  font-size:24px;
  //font-family:'RCI';
}

.gameLandscap  .T1 {
  font-size:60px;
}
.gameLandscap  .T2 {
  font-size: 50px;
  margin-bottom: 18px;
}
.gameLandscap  .nota {
  font-size:14px;
  text-align:right;
}

.gameLandscap  .invisible {
  display:none;
}


.gameLandscap  .grigiochiaro {
  color:#999999;
}

.gameLandscap  .grigioscuro {
  color:#555;
}

.gameLandscap  .azzurro
{
  color: #3aaddb;
}

.gameLandscap  .marginLine {
  margin-top: 20px;
  margin-bottom:20px;
}

.gameLandscap  .leftAdjustImg {
  margin-left:35px;
}

.gameLandscap  .intTimer {
  font-size: 24px;
  /*left: 510px;
  position: relative;
  top: -128px;*/
  color:#ff931e;
  display: table-cell;
  vertical-align: middle;
}

.gameLandscap  .titolo {
  margin-left: -131px;
  margin-top: 19px;
}

.gameLandscap  .firstRow {
  max-height: 200px;
}

.gameLandscap  .secondRow {
  max-height:250px;
}

.gameLandscap  .numberCircle {
  border-radius: 50%;
  width: 103px;
  height: 103px;
  padding: 4px;
  /*  background: #fff; */
  border: 4px solid #ff931e;
  color: #ff931e;
  text-align: center;
  /*margin-left:480px;*/
  display: table;
  margin-top: 14px;
}

/* loading */


.gameLandscap  .loadingModals {display:none;height:100%;width:100%;position:fixed;left:0;top:0;z-index:999999999;background-color:#C0C0C0; opacity:0.6; -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=50)"; filter: alpha(opacity=50); -moz-opacity: 0.5; -khtml-opacity: 0.5;}
.gameLandscap  .loadingBoxes {width:100px;height:100px;top:100px;left:100px;position:absolute;}
.gameLandscap  .loadingModals p.loadingMod { height:50px;width:200px;z-index:999999999;}
.gameLandscap  .af_document_splash-screen {
  background-color: white;
  color: black;
  display: table;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3201;
}
.gameLandscap  .af_document_splash-screen-cell {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}
.gameLandscap  .af_document_splash-screen-content {
  display: table;
  margin-left: auto;
  margin-right: auto;
  white-space: nowrap;
}
.gameLandscap  #container
{
  margin: 0px auto;
}

.gameLandscap  .msgBox
{
  text-align: center;
}

.gameLandscap  .chiudereText
{
  color: #ff931e;
  //font-family: "RCI-Bold";
}

.gameLandscap  .buttonContainer
{
  width: 100%;
  text-align: center;
  display:flex;
}

.gameLandscap  .orangeButtons
{
  background-color: #ff931e;
  border: 0 solid transparent;
  border-radius: 1px;
  color: white;
  //font-family: RCI-Bold;
  font-size: 36px;
  height: 48px;
  margin: 30px 40px 0;
  width: 220px;
}

.fancybox-close
{
  display: none;
}

.fancybox-overlay-fixed
{
  overflow-y: hidden !important;
}

.gameLandscap   #informTitle, #regTitle
{
  width: 50%;
  text-align: center;
  font-size: 40px;
  margin: 2% auto 5%;
  line-height: 40px;
}
/**/ /**/ /**/

.gameLandscap  .fanTitle {
  width: 50%;
  text-align: center;
  //font-family: "RCI-Bold";
  font-size: 40px;
  margin: 2% auto 5%;
  line-height: 40px;
}

.gameLandscap  .fanText {
  font-size: 22px;
  text-align:center;
  margin: 0px auto;
  width:90%;
}

.gameLandscap  .formTitle
{
  font-size: 36px;
  line-height: 36px;
}

.gameLandscap  .labelConsensi
{
  background-color: #EEE !important;
  border-left: transparent;
}

.gameLandscap  .inputField
{
  font-size: 26px;
  height: 48px;
}

.gameLandscap  .inputField:active, .inputField:focus
{
  border-color: #ff931e;
}


.gameLandscap  .modal-footer
{
  text-align: center;
}

.gameLandscap  .testiLegal
{
  font-size: 18px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.gameLandscap #timerContainer, #intTimer
{
  /*display: flex; */
  display: table-cell;
  vertical-align: middle;
}

.gameLandscap  #titleOuter
{
  margin-top: 24px;
}

.gameLandscap   #form2Body, #form2Footer
{
  width: 780px;
  margin: 0px auto;
  text-align: center;
}

.gameLandscap   #intTimer1, #intTimer2
{
  //font-family: 'RCI';
}

.gameLandscap  #form2Footer
{
  text-align: center;
}

.gameLandscap  .separator
{
  display: flex;
  justify-content: center;
  margin: 24px auto;
}

.gameLandscap  #separatorLine
{
  height: 2px;
  width: 82px;
  border: 2px solid transparent;
  border: 2px solid #ff931e;
}

.gameLandscap  #reg2ThirdLine
{
  margin-bottom: 48px;
}

.gameLandscap  #reg2FirstLine
{
  line-height: 1em;
}

.gameLandscap  #reg2SecondLine
{
  font-size: 42px;
  line-height: 1em;
  margin-top: 16px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}


.gameLandscap  .modal-open
{
  padding-right: 0px;
}

.gameLandscap  #provincia
{
  text-transform: uppercase;
}

.gameLandscap  #boxcard
{
  opacity: 0.4;
  transition: opacity 1s ease;
}

.gameLandscap .greyButtons
{
  background-color: #282832;
  border: 0 solid transparent;
  border-radius: 1px;
  color: white;
  //font-family: RCI-Bold;
  font-size: 36px;
  height: 48px;
  margin: 30px 40px 0;
  width: 220px;
}


.gameLandscap #boxcard div {
  float: left;
  width: 200px;
  height: 200px;
  z-index: 2;
  position:relative;
  margin:5px;
  overflow:hidden;
}
.gameLandscap #boxcard {
  /*margin: auto 60px;*/
  padding-left: 102px;
}

.gameLandscap .turned {
  width: 200px;
  height: 200px;
}

.gameLandscap .cards_hover{
  /*background: url("../resources/images/card_back_selection.png");
  opacity: 0.6;*/
  opacity:0.99;
}
.gameLandscap .cards_hover .card {
  position:relative;
  left:2px;
  top:3px;
}
.gameLandscap .cards_unsel{
  background: url("../assets/gameImg/sfondo.jpg");
  border: 2px solid #ff7a01;
}
.gameLandscap .cards_unsel .card {
  position:relative;
  left:0;
  top:0;
}
.gameLandscap #boxcard div img {
  border: none;
  z-index: 3;
}

.gameLandscap .opacity {
  /*opacity: 0.6;
  filter: alpha(opacity=60);*/
}

.gameLandscap .done {

}

.gameLandscap #punteggio{
  width:188px;
  height:141px;
  display:block;
  /*background-image:url("../resources/images/base_punti.png");*/
  position:absolute;
  left:20px;
  top:487px;
}

.gameLandscap #count{
  width:100px;
  height:50px;
  position:relative;
  top:65px;
  left:45px;
  text-align:center;
  font-size:40px;
  color:#1d8ac7;
}

.gameLandscap #btn_popup{
  width:354px;
  height:138px;
  background-repeat:no-repeat;
  color:#1d8ac7;
  font-size:200%;
  margin-left:25px;
  padding-top:15px;
  text-shadow: 2px 2px 2px #ffffff;
}

.gameLandscap .butt{
  cursor:pointer;
}

.gameLandscap .clear {
  clear: both;
}
.gameLandscap .visible{
  visibility: visible;
  display: block;
}
.gameLandscap .invisible{
  visibility: hidden;
  display: none;
}

.gameLandscap #clear {
  position: relative;
  height: 200px;
  width: 400px;
  text-align: center;
  margin: 0 auto;
  top: 0px;
  display: none;
}

.gameLandscap .text_login{
  color:black;
}

/*CUSTOM POPUP*/

.gameLandscap .fancybox-skin{
  padding:0px !important;
  border-top:10px solid #ff931e !important;
  border-bottom:10px solid #ff931e !important;
}

.gameLandscap .fancybox-outer, .fancybox-inner{
  padding-top:10px;
  padding-bottom:10px;
}

//END GAME LANDSCAPE
//START GAME PORTRAIT

.gamePotrait #container {
  border-bottom: 10px solid #ff931e;
  border-top: 10px solid #ff931e;
  color: #555;
  //font-family: "RCI-Bk" !important;
  height: 1440px;
  width: 1080px;
}

.gamePotrait .registrazione { /**/
  margin-top:250px;
}

.gamePotrait .centered {
  text-align:center;
}
.gamePotrait .inputField {
  margin-bottom:10px;
  height: 30px;
}
.gamePotrait .vmiddle{
  vertical-align:middle;
}
.gamePotrait .errorInput {
  border: solid 1px red !important;
}
.gamePotrait .errorMsg {
  color: red;
  font-weight: bold;
  text-align: center;
}

.gamePotrait .sizeModal {
  width: 780px !important;
  margin: 0px auto;
}
.gamePotrait .size48{
  font-size:48px;
}
.gamePotrait .arancione {
  color: #ff931e;
}

.gamePotrait .nero {
  color:black;
}

.gamePotrait .marginTop60 {
  margin-top: 5px;
  margin-bottom: 10px;
}

.gamePotrait .centered {
  text-align: center;
}

.gamePotrait #giocaCTA,#giocaCTAPlay {
  margin-top: 20px;
  text-align:center;
  margin-right: 0 !important;
}

.gamePotrait .bold
{
  //font-family: 'RCI-Bold';
  font-weight: bold;
}

.gamePotrait .bold24 {
  font-size:24px;
  //font-family:'RCI-Bold' !important;
  color:#ff931e;
  font-weight: bold;
  position:relative;
  top:-10px;
  line-height:25px;
}

.gamePotrait .T1 {
  font-size:60px;
}
.gamePotrait .T2 {
  font-size: 50px;
  margin-bottom: 18px;
}
.gamePotrait .nota {
  font-size:14px;
  text-align:right;
}

.gamePotrait .invisible {
  display:none;
}


.gamePotrait .grigiochiaro {
  color:#999999;
}

.gamePotrait .grigioscuro {
  color:#333333;
}

.gamePotrait .azzurro
{
  color: #3aaddb;
}

.gamePotrait .marginLine {
  margin-top: 20px;
  margin-bottom:20px;
}

.gamePotrait .leftAdjustImg {
  margin-left:35px;
}

.gamePotrait .intTimer {
  font-size: 45px;
  /*left: 510px;
  position: relative;
  top: -128px;*/
  color:#ff931e;
  display: table-cell;
  vertical-align: middle;
}

.gamePotrait .firstRow {
  max-height: 200px;
}

.gamePotrait .secondRow {
  max-height:250px;
}

.gamePotrait .numberCircle {
  //CIRCLE TURNED
  border-radius: 50%;
  width: 150px;
  height: 150px;
  padding: 8px;
  /*  background: #fff; */
  border: 4px solid #ff931e;
  color: #ff931e;
  text-align: center;
  /*margin-left:480px;*/
  font-size: 24px;
  //font-weight:bold;
  display: table;
}

/* loading */
.gamePotrait .loadingModals {display:none;height:100%;width:100%;position:fixed;left:0;top:0;z-index:999999999;background-color:#C0C0C0; opacity:0.6; -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=50)"; filter: alpha(opacity=50); -moz-opacity: 0.5; -khtml-opacity: 0.5;}
.gamePotrait .loadingBoxes {width:100px;height:100px;top:100px;left:100px;position:absolute;}
.gamePotrait .loadingModals p.loadingMod { height:50px;width:200px;z-index:999999999; }
.gamePotrait .af_document_splash-screen {
  background-color: white;
  color: black;
  display: table;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3201;
}
.gamePotrait .af_document_splash-screen-cell {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}
.gamePotrait .af_document_splash-screen-content {
  display: table;
  margin-left: auto;
  margin-right: auto;
  white-space: nowrap;
}
/*end loading */



/**//**//**//**/

.gamePotrait #container
{
  margin: 0px auto;
}

.gamePotrait .msgBox
{
  text-align: center;
}

.gamePotrait .chiudereText
{
  color: #ff931e;
  //font-family: "RCI-Bold";
}

.gamePotrait .buttonContainer
{
  width: 100%;
  text-align: center;
  display:flex;
}

.gamePotrait .orangeButtons
{
  background-color: #ff931e;
  border: 0 solid transparent;
  border-radius: 1px;
  color: white;
  //font-family: RCI-Bold;
  font-size: 36px;
  height: 48px;
  margin: 30px 40px 0;
  width: 220px;
}

.gamePotrait .greyButtons
{
  background-color: #282832;
  border: 0 solid transparent;
  border-radius: 1px;
  color: white;
  //font-family: RCI-Bold;
  font-size: 36px;
  height: 48px;
  margin: 30px 40px 0;
  width: 220px;
}


.fancybox-close
{
  display: none;
}

.fancybox-overlay-fixed
{
  overflow-y: hidden !important;
}

.gamePotrait #informTitle, #regTitle
{
  width: 50%;
  text-align: center;
  font-size: 40px;
  margin: 2% auto 5%;
  line-height: 40px;
}
/**/ /**/ /**/

.gamePotrait .fanTitle {
  width: 50%;
  text-align: center;
  //font-family: "RCI-Bold";
  font-size: 40px;
  margin: 2% auto 5%;
  line-height: 40px;
}

.gamePotrait .fanText {
  font-size: 22px;
  text-align:center;
  margin: 0px auto;
  width:90%;
}

.gamePotrait .formTitle
{
  font-size: 36px;
  line-height: 36px;
}

.gamePotrait .labelConsensi
{
  background-color: #EEE !important;
  border-left: transparent;
}

.gamePotrait .inputField
{
  font-size: 26px;
  height: 48px;
}

.gamePotrait .inputField:active, .inputField:focus
{
  border-color: #ff931e;
}


.gamePotrait .modal-footer
{
  text-align: center;
}

.gamePotrait .testiLegal
{
  font-size: 18px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.gamePotrait #timerContainer
{
  display: table;
  margin: 0 auto;
}
.gamePotrait #intTimer
{
  display: table-cell;
  vertical-align: middle;
}

.gamePotrait #titleOuter
{
  margin-top: 24px;
}

.gamePotrait #form2Body, #form2Footer
{
  //width: 780px;
  margin: 0px auto;
  text-align: center;
}

.gamePotrait #intTimer1, #intTimer2
{
  //font-family: 'RCI';
}

.gamePotrait #form2Footer
{
  text-align: center;
}

.gamePotrait .separator
{
  display: flex;
  justify-content: center;
  margin: 24px auto;
}

.gamePotrait #separatorLine
{
  height: 2px;
  width: 82px;
  border: 2px solid transparent;
  border: 2px solid #ff931e;
}

.gamePotrait #reg2ThirdLine
{
  margin-bottom: 48px;
}

.gamePotrait #reg2SecondLine
{
  font-size: 42px;
}

.gamePotrait #boxcard
{
  opacity: 0.4;
  transition: opacity 1s ease;
}


.gamePotrait #boxcard div {
  float: left;
  width: 200px;
  height: 200px;
  z-index: 2;
  position:relative;
  margin:5px;
  overflow:hidden;
}
.gamePotrait #boxcard {
  margin: auto 0px auto 120px;
}

.gamePotrait .turned {
  width: 200px;
  height: 200px;
}

.gamePotrait .cards_hover{
  /*background: url("../resources/images/card_back_selection.png");
  opacity: 0.6;*/
  opacity:0.99;
}
.gamePotrait .cards_hover .card {
  position:relative;
  left:2px;
  top:3px;
}
.gamePotrait .cards_unsel{
  background: url("../assets/gameImg/sfondo.jpg");
  border: 2px solid #ff7a01;
}
.gamePotrait .cards_unsel .card {
  position:relative;
  left:0;
  top:0;
}


.gamePotrait #boxcard div img {
  border: none;
  z-index: 3;
}

.gamePotrait .opacity {
  /*opacity: 0.6;
  filter: alpha(opacity=60);*/
}

.gamePotrait .done {

}

.gamePotrait #punteggio{
  width:188px;
  height:141px;
  display:block;
  /*background-image:url("../resources/images/base_punti.png");*/
  position:absolute;
  left:20px;
  top:487px;
}

.gamePotrait #count{
  width:100px;
  height:50px;
  position:relative;
  top:65px;
  left:45px;
  text-align:center;
  font-size:40px;
  color:#1d8ac7;
}

.gamePotrait #btn_popup{
  width:354px;
  height:138px;
  background-repeat:no-repeat;
  color:#1d8ac7;
  font-size:200%;
  margin-left:25px;
  padding-top:15px;
  text-shadow: 2px 2px 2px #ffffff;
  font-family: "BADABB__";
}


.gamePotrait .butt{
  cursor:pointer;
}

.gamePotrait .clear {
  clear: both;
}
.gamePotrait .visible{
  visibility: visible;
  display: block;
}
.gamePotrait .invisible{
  visibility: hidden;
  display: none;
}

.gamePotrait #clear {
  position: relative;
  height: 200px;
  width: 400px;
  text-align: center;
  margin: 0 auto;
  top: 0px;
  display: none;
}

.gamePotrait .text_login{
  color:black;
}

/*CUSTOM POPUP*/
.fancybox-skin{
  padding:0px !important;
  border-top:10px solid #ff931e !important;
  border-bottom:10px solid #ff931e !important;
}

.fancybox-outer, .fancybox-inner{
  padding-top:10px !important;
  padding-bottom:10px !important;
}

//EQUIVALENTE COL-MD-3
.gameLandscap .floatLeftGame{
  float: left;
  width:25%;
}.gameLandscap .rowBootstrap{
   display: block;
   clear: both;
   overflow: hidden;
 }


 //MODAL TITLE
.gameLandscap .modal-title{
  line-height:1em !important;
}
.gamePotrait .modal-title{
  line-height:1em !important;
}

.gamePotrait #form2Header
{
  width: 780px;
  margin: 0px auto;
  text-align: center;
}
.gamePotrait #form2Body
{
  width: 780px;
  margin: 0px auto;
  text-align: center;
}

.gameLandscap #form2Header
{
  width: 780px;
  margin: 0px auto;
  text-align: center;
}
.gameLandscap #form2Body
{
  width: 780px;
  margin: 0px auto;
  text-align: center;
}

