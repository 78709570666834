/*//////////////////GLOBALI//////////////////*/

@font-face {
    font-family: 'montserratregular';
    src: url('../assets/fonts/meteo/Montserrat-Regular.eot');
    src: url('../assets/fonts/meteo/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/meteo/Montserrat-Regular.woff') format('woff'),
    url('../assets/fonts/meteo/Montserrat-Regular.ttf') format('truetype'),
    url('../assets/fonts/meteo/Montserrat-Regular.svg#montserratregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Montserrat-Bold';
    src: url('../assets/fonts/meteo/Montserrat-Bold.eot');
    src: url('../assets/fonts/meteo/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/meteo/Montserrat-Bold.woff') format('woff'),
    url('../assets/fonts/meteo/Montserrat-Bold.ttf') format('truetype'),
    url('../assets/fonts/meteo/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
}

/*//////////////////METEO//////////////////*/

/*.meteo{*/
/*height:328px;*/
/*width: 670px;*/
/*}*/


/*//////////////////BOX METEO//////////////////*/

.floatleft {
    float:left;
}

#ricerca_meteo{
    text-transform: capitalize;
}

#ricerca_meteo input{
    color: darkgrey !important;
}

#temp_oggi{
margin-top: 0px !important;  
}

/*INIZIO METEO 1X*/

#meteo_box_1X{
    width: 100%;
    height: 100%;
    background-size:cover;
    text-shadow: 0 0 5px #000000;
    position: relative;
    text-align: left;
}

.containertitleMeteo{
    width: 316px;	
    padding-top: 30px;
}

#meteo_box_1X #temp_oggi{
margin-top:0px !important;	
}

#meteo_box_1X #testo_errore {
    color: #FFF;
    font-size: 31px;
    text-align: center;
    clear: both;
    padding-top: 55px;
    font-family: montserratregular;
    line-height: 40px; 
}

#meteo_box_1X #tempo_oggi {
    width: 270px;
    height: 264px;
    padding: 25px;
    padding-right:0px;
    color:#FFF;
    float: left;
    padding-top: 19px;
    padding-left: 30px;
}

#meteo_box_1X #gradi_citta {
    font-family:'montserratregular';
    font-size: 68px;
    margin-left: 32px;
    margin-top: 14px;
}

#meteo_box_1X #info_oggi {
    font-family:'montserratregular';
    font-size:14px;
    padding: 10px 0px;
    clear: both;
    margin-top: 24px;
}

#meteo_box_1X #meteo_citta {
    font-family:'montserratregular';
    font-size:29px;
}

#meteo_box_1X .icoWeather {
    padding-top:6px;
    padding-left:6px;
}

#meteo_box_1X .box_meteooggi {
    margin-top:15px;
    line-height:18px;
}

#meteo_box_1X #meteo_boxdestra {
   width: 656px;
    /* float: left; */
    position: absolute;
    top: 18px;
    right: 140px;
}

#meteo_box_1X #ricerca_meteo {
    width:368px;
    height:150px;
}

#meteo_box_1X #meteo_next {
    margin-top: 106px;
    display: none;
}

#meteo_box_1X .dettaglio_next {
    float:left;
    width:123px;
    height:170px;
    text-align: center;
    color:#FFF;
}

#meteo_box_1X .titolo_next {
    text-align: center;
    font-family:'montserratregular';
    font-size:14px;
    color:#FFF;
}

#meteo_box_1X .img_next {
    height:90px;
    border-right: 1px solid #FFF;
}

#meteo_box_1X .img_next img {
    margin-top: 3px;
}

#meteo_box_1X .descr_next {
    text-align: center;
    font-family:'montserratregular';
    font-size:14px;
    color:#FFF;
    height: 39px;
    border-right: 1px solid #FFF;
    line-height: 19px;
    padding: 4px 5px;
}

/* input ricerca meteo */
#meteo_box_1X #ricerca_meteo {
    background-image: url('../assets/meteo/cerca_meteo.png');
    height: 41px;
    width: 280px;
    margin-top: 0px;
    //margin-left: 464px;
    z-index: 10;
}

#meteo_box_1X #ricerca_meteo input {
    background: transparent;
    border: 0;
    height: 30px;
    padding: 4px;
    margin-left: 40px;
    width: 230px;
    outline:none;
    font-size:14px;
    font-weight: bold;
}
/* input ricerca meteo */

/* FINE BOX METEO */

/* METEO 2X */

#meteo_box_2X{
    width: 100%;
    height: 100%;
    background-size:cover;
    text-shadow: 0 0 5px #000000;
    text-align: left;
}

#meteo_box_2X #testo_errore {
    color: #FFF;
    font-size: 31px;
    text-align: center;
    clear: both;
    padding-top: 55px;
    font-family: montserratregular;
    line-height: 40px; 
}

#meteo_box_2X #tempo_oggi {
    width: 300px;
    height: 288px;
    padding: 25px;
    padding-right: 0px;
    color: #FFF;
    float: left;
    padding-left: 35px;
}

#meteo_box_2X #gradi_citta {
    font-family:'montserratregular';
    font-size: 68px;
    margin-left: 26px;
    margin-top: 14px;
}

#meteo_box_2X #info_oggi {
    font-family:'montserratregular';
    font-size:14px;
    padding: 10px 0px;
    //border-right: 1px solid #FFF;
    clear: both;
    margin-top: 33px;
}

#meteo_box_2X #meteo_citta {
    font-family:'montserratregular';
    font-size:32px;
}

#meteo_box_2X .icoWeather {
    padding-top:6px;
    padding-left:6px;
}

#meteo_box_2X .box_meteooggi {
    margin-top:15px;
    line-height:18px;
}

#meteo_box_2X #meteo_boxdestra {
    width: 350px;
    float: left;
}

#meteo_box_2X #ricerca_meteo {
    width:368px;
    height:150px;
}

#meteo_box_2X #meteo_next {
    margin-top: 88px;
}

#meteo_box_2X .dettaglio_next {
    float:left;
    width:123px;
    height:170px;
    text-align: center;
}

#meteo_box_2X .titolo_next {
    text-align: center;
    font-family:'montserratregular';
    font-size:14px;
    color:#FFF;
}

#meteo_box_2X .img_next {
    height:90px;
    border-right: 1px solid #FFF;
}

#meteo_box_2X .img_next img {
    margin-top: 3px;
}

#meteo_box_2X .descr_next {
    text-align: center;
    font-family:'montserratregular';
    font-size:14px;
    color:#FFF;
    height: 39px;
    border-right: 1px solid #FFF;
    line-height: 19px;
    padding: 4px 5px;
}

/* input ricerca meteo */
#meteo_box_2X #ricerca_meteo {
    background-image: url('../assets/meteo/cerca_meteo.png');
    height: 41px;
    width: 280px;
    margin-top: 20px;
    margin-left: 94px;
    z-index: 10;
}

#meteo_box_2X #ricerca_meteo input {
    background: transparent;
    border: 0;
    height: 30px;
    padding: 4px;
    margin-left: 40px;
    width: 230px;
    outline:none;
    font-size:14px;
    font-weight: bold;
}
/* input ricerca meteo */

/* FINE BOX METEO */

/*INIZIO METEO 3X*/

#meteo_box_3X{
    width: 100%;
    height: 100%;
    background-size:cover;
    text-shadow: 0 0 5px #000000;
    text-align: left;
}

#meteo_box_3X #testo_errore {
    color: #FFF;
    font-size: 31px;
    text-align: center;
    clear: both;
    padding-top: 55px;
    font-family: montserratregular;
    line-height: 40px; 
}

#meteo_box_3X #tempo_oggi {
    width: 407px;
    height: 288px;
    padding: 25px;
    padding-right: 0px;
    color: #FFF;
    float: left;
    padding-left: 75px;
}

#meteo_box_3X #gradi_citta {
    font-family:'montserratregular';
    font-size: 68px;
    margin-left: 26px;
    margin-top: 14px;
}

#meteo_box_3X #info_oggi {
    font-family:'montserratregular';
    font-size:14px;
    padding: 10px 0px;
    //border-right: 1px solid #FFF;
    clear: both;
    margin-top: 40px;
}

#meteo_box_3X #meteo_citta {
    font-family:'montserratregular';
    font-size:32px;
}

#meteo_box_3X .icoWeather {
    padding-top:6px;
    padding-left:6px;
}

#meteo_box_3X .box_meteooggi {
    margin-top:15px;
    line-height:18px;
}

#meteo_box_3X #meteo_boxdestra {
    width: 578px;
    float: left;
}

#meteo_box_3X #ricerca_meteo {
    width:368px;
    height:150px;
}

#meteo_box_3X #meteo_next {
    margin-top: 80px;
}

#meteo_box_3X .dettaglio_next {
    float:left;
    width: 135px;
    height:170px;
    text-align: center;
    color:#FFF;
}

#meteo_box_3X .titolo_next {
    text-align: center;
    font-family:'montserratregular';
    font-size:14px;
    color:#FFF;
}

#meteo_box_3X .img_next {
    height:90px;
    border-right: 1px solid #FFF;
}

#meteo_box_3X .img_next img {
    margin-top: 3px;
}

#meteo_box_3X .descr_next {
    text-align: center;
    font-family:'montserratregular';
    font-size:14px;
    color:#FFF;
    height: 39px;
    border-right: 1px solid #FFF;
    line-height: 19px;
    padding: 4px 5px;
}

/* input ricerca meteo */
#meteo_box_3X #ricerca_meteo {
    background-image: url('../assets/meteo/cerca_meteo.png');
    height: 41px;
    width: 280px;
    margin-top: 20px;
    margin-left: 264px;
    z-index: 10;
}

#meteo_box_3X #ricerca_meteo input {
    background: transparent;
    border: 0;
    height: 30px;
    padding: 4px;
    margin-left: 40px;
    width: 230px;
    outline:none;
    font-size:14px;
    font-weight: bold;
}
/* input ricerca meteo */

/* FINE BOX METEO */

/*INIZIO METEO 3X*/

#meteo_box_A{
    width: 100%;
    height: 100%;
    background-size:cover;
    text-shadow: 0 0 5px #000000;
    text-align: left;
}

#meteo_box_A .containertitleMeteo{
    width: 390px;	
    padding-top: 110px;
}


#meteo_box_A #testo_errore {
    color: #FFF;
    font-size: 31px;
    text-align: center;
    clear: both;
    padding-top: 55px;
    font-family: montserratregular;
    line-height: 40px; 
}

#meteo_box_A #tempo_oggi {
    width: 270px;
    height: 288px;
    padding: 25px;
    padding-right:0px;
    color:#FFF;
    float: left;
    padding-left: 35px;
}

#meteo_box_A #gradi_citta {
    font-family:'montserratregular';
    font-size: 88px;
    margin-left: 26px;
    margin-top: 0px;
}

#meteo_box_A #info_oggi {
    font-family:'montserratregular';
    font-size:14px;
    padding: 10px 0px;
    border-right: 1px solid #FFF;
    clear: both;
    margin-top: 76px;
}

#meteo_box_A #meteo_citta {
    font-family:'montserratregular';
    font-size:66px;
}

#meteo_box_A .icoWeather {
    padding-top:6px;
    padding-left:6px;
}

#meteo_box_A .box_meteooggi {
    margin-top:15px;
    line-height:18px;
}

#meteo_box_A #meteo_boxdestra {
    width: 690px;
    float: left;
}

#meteo_box_A #ricerca_meteo {
    width:368px;
    height:150px;
}

#meteo_box_A #meteo_next {
    margin-top: 234px;
}

#meteo_box_A .dettaglio_next {
    float:left;
    width:123px;
    height:170px;
    text-align: center;
    color:#FFF;
}

#meteo_box_A .titolo_next {
    text-align: center;
    font-family:'montserratregular';
    font-size:14px;
    color:#FFF;
}

#meteo_box_A .img_next {
    height:90px;
    border-right: 1px solid #FFF;
}

#meteo_box_A .img_next img {
    margin-top: 3px;
}

#meteo_box_A .descr_next {
    text-align: center;
    font-family:'montserratregular';
    font-size:14px;
    color:#FFF;
    height: 39px;
    border-right: 1px solid #FFF;
    line-height: 19px;
    padding: 4px 5px;
}

/* input ricerca meteo */
#meteo_box_A #ricerca_meteo {
    background-image: url('../assets/meteo/cerca_meteo.png');
    height: 41px;
    width: 280px;
        margin-top: 40px;
    margin-left: 418px;
    z-index: 10;
}

#meteo_box_A #ricerca_meteo input {
    background: transparent;
    border: 0;
    height: 30px;
    padding: 4px;
    margin-left: 40px;
    width: 230px;
    outline:none;
    font-size:14px;
    font-weight: bold;
}
/* input ricerca meteo */

/* FINE BOX METEO */


/*INIZIO METEO B1*/

#meteo_box_B1{
    width: 100%;
    height: 100%;
    background-size:cover;
    text-shadow: 0 0 5px #000000;
    text-align: left;
}

#meteo_box_B1 .containertitleMeteo{
    width: 390px;	
    padding-top: 110px;
}


#meteo_box_B1 #testo_errore {
    color: #FFF;
    font-size: 31px;
    text-align: center;
    clear: both;
    padding-top: 55px;
    font-family: montserratregular;
    line-height: 40px; 
}

#meteo_box_B1 #tempo_oggi {
    width: 270px;
    height: 288px;
    padding: 25px;
    padding-right:0px;
    color:#FFF;
    float: left;
    padding-left: 35px;
}

#meteo_box_B1 #gradi_citta {
    font-family:'montserratregular';
    font-size: 88px;
    margin-left: 26px;
    margin-top: 0px;
}

#meteo_box_B1 #info_oggi {
    font-family:'montserratregular';
    font-size:14px;
    padding: 10px 0px;
    border-right: 1px solid #FFF;
    clear: both;
    margin-top: 76px;
}

#meteo_box_B1 #meteo_citta {
    font-family:'montserratregular';
    font-size:66px;
}

#meteo_box_B1 .icoWeather {
    padding-top:6px;
    padding-left:6px;
}

#meteo_box_B1 .box_meteooggi {
    margin-top:15px;
    line-height:18px;
}

#meteo_box_B1 #meteo_boxdestra {
    width: 690px;
    float: left;
}

#meteo_box_B1 #ricerca_meteo {
    width:368px;
    height:150px;
}

#meteo_box_B1 #meteo_next {
    margin-top: 234px;
}

#meteo_box_B1 .dettaglio_next {
    float:left;
    width:123px;
    height:170px;
    text-align: center;
    color:#FFF;
}

#meteo_box_B1 .titolo_next {
    text-align: center;
    font-family:'montserratregular';
    font-size:14px;
    color:#FFF;
}

#meteo_box_B1 .img_next {
    height:90px;
    border-right: 1px solid #FFF;
}

#meteo_box_B1 .img_next img {
    margin-top: 3px;
}

#meteo_box_B1 .descr_next {
    text-align: center;
    font-family:'montserratregular';
    font-size:14px;
    color:#FFF;
    height: 39px;
    border-right: 1px solid #FFF;
    line-height: 19px;
    padding: 4px 5px;
}

/* input ricerca meteo */
#meteo_box_B1 #ricerca_meteo {
    background-image: url('../assets/meteo/cerca_meteo.png');
    height: 41px;
    width: 280px;
        margin-top: 40px;
    margin-left: 418px;
    z-index: 10;
}

#meteo_box_B1 #ricerca_meteo input {
    background: transparent;
    border: 0;
    height: 30px;
    padding: 4px;
    margin-left: 40px;
    width: 230px;
    outline:none;
    font-size:14px;
    font-weight: bold;
}
/* input ricerca meteo */

/* FINE BOX METEO */

/*INIZIO METEO B2*/

#meteo_box_B2{
    width: 100%;
    height: 100%;
    background-size:cover;
    text-shadow: 0 0 5px #000000;
    text-align: left;
}

#meteo_box_B2 #temp_oggi{
margin-top: 30px !important;  
}


#meteo_box_B2 .containertitleMeteo{
    width: 390px;	
    padding-top: 110px;
}


#meteo_box_B2 #testo_errore {
    color: #FFF;
    font-size: 31px;
    text-align: center;
    clear: both;
    padding-top: 55px;
    font-family: montserratregular;
    line-height: 40px; 
}

#meteo_box_B2 #tempo_oggi {
    width: 270px;
    height: 288px;
    padding: 25px;
    padding-top: 45px;
    padding-right:0px;
    color:#FFF;
    float: left;
    padding-left: 35px;
    padding-top: 0px;
}

#meteo_box_B2 #gradi_citta {
    font-family:'montserratregular';
    font-size: 88px;
    margin-left: 26px;
    margin-top: 0px;
}

#meteo_box_B2 #info_oggi {
   font-family: 'montserratregular';
    font-size: 20px;
    padding: 10px 0px;
    border-right: 1px solid #FFF;
    clear: both;
    margin-top: 76px;
    width: 510px;
    line-height: 100px;
}

#meteo_box_B2 #meteo_citta {
    font-family:'montserratregular';
    font-size:66px;
}

#meteo_box_B2.icoWeather {
    padding-top:6px;
    padding-left:6px;
}

#meteo_box_B2 .box_meteooggi {
    margin-top: 24px;
    line-height: 30px;
}

#meteo_box_B2 #meteo_boxdestra {
    width: 690px;
    float: left;
        margin-top: 300px;
}

#meteo_box_B2 #ricerca_meteo {
    width:368px;
    height:150px;
}

#meteo_box_B2 #meteo_next {
    margin-top: 100px;
}

#meteo_box_B2 .dettaglio_next {
    float:left;
    width:123px;
    height:170px;
    text-align: center;
    color:#FFF;
}

#meteo_box_B2 .titolo_next {
    text-align: center;
    font-family:'montserratregular';
    font-size:14px;
    color:#FFF;
}

#meteo_box_B2 .img_next {
    height:90px;
    border-right: 1px solid #FFF;
}

#meteo_box_B2 .img_next img {
    margin-top: 3px;
}

#meteo_box_B2 .descr_next {
    text-align: center;
    font-family:'montserratregular';
    font-size:14px;
    color:#FFF;
    height: 39px;
    border-right: 1px solid #FFF;
    line-height: 19px;
    padding: 4px 5px;
}

/* input ricerca meteo */
#meteo_box_B2 #ricerca_meteo {
    background-image: url('../assets/meteo/cerca_meteo.png');
    height: 41px;
    width: 280px;
    margin-top: 40px;
    margin-bottom: 60px;
    margin-left: 35px;
    z-index: 10;
}

#meteo_box_B2 #ricerca_meteo input {
    background: transparent;
    border: 0;
    height: 30px;
    padding: 4px;
    margin-left: 40px;
    width: 230px;
    outline:none;
    font-size:14px;
    font-weight: bold;
}
/* input ricerca meteo */

/* FINE BOX METEO */

